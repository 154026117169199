export type Success<T> = { success: T };
export type Failure<T> = { failure: { status: number, statusText: string, payload: T } };
export type Error = { error: any };
export type Result<T,E> = Success<T> | Failure<E> | Error;

export function isSuccess<T, E>(r: Result<T,E>): r is Success<T> {
    return 'success' in r;
}

export function isFailure<T, E>(r: Result<T,E>): r is Failure<E> {
    return 'failure' in r;
}

export function isError<T, E>(r: Result<T,E>): r is Error {
    return 'error' in r;
}

export function match<T, E, R>(r: Result<T, E>, onSuccess: (data: T) => R, onFailure: (error: { status: number, statusText: string, payload: E }) => R, onError: (error: any) => R) {
    if (isSuccess(r)) {
        return onSuccess(r.success);
    } else if (isFailure(r)) {
        return onFailure(r.failure);
    } else {
        return onError(r.error);
    }
}



export function GET<T, E>(url: string) { return Request<T, E>('GET', url, undefined); }
export function POST<T, E>(url: string, data: any) { return Request<T,E>('POST', url, data); }
export function PUT<T, E>(url: string, data: any) { return Request<T, E>('PUT', url, data); }
export function DELETE<T, E>(url: string) { return Request<T, E>('DELETE', url, undefined); }

export async function Request<T, E>(method: string, url: string, data: undefined | any): Promise<Success<T> | Failure<E> | Error> {

    try {
        console.log('Calling fetch');
        const f = fetch(url, {
            method: method,
            headers: data === undefined ? undefined : {
                'Content-Type': 'application/json'
            },
            body: data === undefined ? undefined : JSON.stringify(data),

        });
        console.log('Awaiting fetch', f);
        const r = await f;
        console.log('Got fetch', r);
        const payload = await r.json();
        if (r.ok) {
            return { success: <T>payload };
        } else {
            return { failure: { status: r.status, statusText: r.statusText, payload: <E>payload } };
        }
    } catch (error) {
        console.error('Error when calling', { url, method, error });
        return { error };
    }
}


