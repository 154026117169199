import * as React from 'react';
import * as Monaco from 'monaco-editor';
import { useState, useEffect } from 'react';
import * as API from './API';
import * as XVal from './XValAPI';
import { APIResultPresentation } from './Components/APIResult';
import { LoadingIndicator } from './Components/LoadingIndicator';
import { useCallback } from 'react';
import './custom.css';
import { useDelayState, useLocalStoragePersistance, useMonaco, useMonacoDiff } from './hooks';
import { Nav, NavItem, NavLink, TabContent, TabPane, Badge } from 'reactstrap';



function ASTBase({ tree, highlight }: { tree: XVal.AST, highlight: (range: XVal.Range) => void }) {
    return <a href="#" onClick={_ => highlight(tree.range)}>{tree.nodeType}</a>;
}


function AST({ tree, highlight }: { tree: XVal.AST, highlight: (range: XVal.Range) => void }) {
    return <div>
        <ASTBase tree={tree} highlight={highlight} />
        <ul>
            {(tree.children || []).map((c, i) => <li key={i}>
                <AST tree={c} highlight={highlight} />
            </li>)}
        </ul>
    </div>;
}


function ParseErrorReport({ error, highlight }: { error: XVal.ParseErrorReport, highlight: (range: XVal.Range) => void }) {
    return <div>
        <a href="#" onClick={_ => highlight({ start: error.position, end: { index: error.position.index + 1, line: error.position.line, column: error.position.column + 1 } })}>
            Error in Ln: {error.position.line} Col: {error.position.column}<br />
            {error.messages.concat("")}
        </a>
        {Object.keys(error.inner).map(k =>
            <div key={k}>
                <h4>{k}</h4>
                <ul>
                    {error.inner[k].map((err, i) => <li key={i}>
                        <ParseErrorReport error={err} highlight={highlight} />
                    </li>)}
                </ul>
            </div>
        )}
    </div>;
}


function ParseErrors({ errors, highlight }: { errors: XVal.ParseError[], highlight: (range: XVal.Range) => void }) {
    return <ul>
        {(errors || []).map((err, i) => <li key={i}>
            <ParseErrorReport error={err.report} highlight={highlight} />
            <pre>{err.formattedMessage}</pre>

        </li>)}
    </ul>;
}


function Position({ position, highlight }: { position: XVal.Position, highlight: (range: XVal.Range) => void }) {
    return <a href="#" onClick={() => highlight({ start: position, end: position })}>ln:{position.line} col:{position.column}</a>;
}


function EvaluationError({ error, highlight }: { error: XVal.EvaluationErrorReport, highlight: (range: XVal.Range) => void }) {
    let r = XVal.flattenStacks(error);

    //{ast === null ? <div>No AST</div> : <ASTBase tree={ast} highlight={highlight} />}
    return <div><div>
        <ul>
            {'stack' in r && r.stack.map((st, i) => <li key={i}>
                {st === null ? <div>No ST</div> : <div>{st.message}: <Position position={st.position} highlight={highlight} /></div>}
            </li>)}
        </ul>

        {'message' in r.error && r.error.message}
    </div>
    </div>;

}


function useXvalEvaluation(script: string, library: string, input: string) {
    const [result, setResult] = useState<API.Result<XVal.TestResult, any> | 'loading' | null>(null);

    useEffect(() => {
        const update = async () => {
            setResult('loading');
            const r = await XVal.POSTtest(script, library, input);
            setResult(r);
        }

        update();

    }, [library, input, script]);

    return result;
}


function useDataBuffer(key: string, defaultValue: string): [string, (next: string) => void] {
    const [value, setValue] = useDelayState(window.localStorage.getItem(key) || defaultValue);
    useLocalStoragePersistance(key, value);
    return [value, setValue];
}


export function HomeScreen() {
    const [script, setScript] = useDataBuffer('current-script', '6*7');
    const [library, setLibrary] = useDataBuffer('current-library', '{}');
    const [input, setInput] = useDataBuffer('current-input', '{}');
    const [desired, setDesired] = useDataBuffer('current-desired', '{}');

    const [scriptEditor, scriptEditorRef] = useMonaco({ language: 'xval', value: script, onChange: setScript });
    const [inputEditor, inputEditorRef] = useMonaco({ language: 'json', value: input, onChange: setInput });
    const [libraryEditor, libraryEditorRef] = useMonaco({ language: 'xval', value: library, onChange: setLibrary });

    const result = useXvalEvaluation(script, library, input)
    const r = result;
    const resultValue = result && result !== 'loading' && API.isSuccess(result) ? result.success.outputValue : '';

    const [diffEditor, diffEditorRef] = useMonacoDiff(
        { language: 'json', value: typeof resultValue === 'string' ? resultValue : JSON.stringify(resultValue, null, 4) },
        { language: 'json', value: desired, onChange: setDesired }
    );

    const highlight = useCallback((range: XVal.Range) => {
        if (scriptEditor) {
            scriptEditor.setSelection(new Monaco.Range(range.start.line, range.start.column, range.end.line, range.end.column));
            scriptEditor.revealPosition({ lineNumber: range.start.line, column: range.start.column });
            scriptEditor.focus();
        }
    }, [scriptEditor]);



    const [tab1, setTab1] = useState<'env' | 'inp'>('env');
    useEffect(() => {
        inputEditor?.layout();
        libraryEditor?.layout();
    }, [tab1]);

    const [tab2, setTab2] = useState<'desired' | 'result'>('result');
    useEffect(() => {
        diffEditor?.layout();
    }, [tab2]);

    return <>

        <div id="env-input" className="tabbedSection">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={tab1 === 'env' ? 'active' : ''}
                        onClick={() => { setTab1('env'); }}
                    >
                        Environment
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        className={tab1 === 'inp' ? 'active' : ''}
                        onClick={() => { setTab1('inp'); }}
                    >
                        Input
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={tab1}>
                <TabPane tabId="env">
                    <div ref={libraryEditorRef} id="envEditor" className="editor" />
                </TabPane>
                <TabPane tabId="inp">
                    <div ref={inputEditorRef} id="inputEditor" className="editor" />
                </TabPane>
            </TabContent>
        </div>

        <div id="script">
            <div ref={scriptEditorRef} id="scriptEditor" className="editor" />
        </div>

        <div id="results" className="tabbedSection">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={tab2 === 'result' ? 'active' : ''}
                        onClick={() => { setTab2('result'); }}
                    >
                        Current results
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        className={tab2 === 'desired' ? 'active' : ''}
                        onClick={() => { setTab2('desired'); }}
                    >
                        Desired results
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={tab2}>
                <TabPane tabId="result">
                    <div className="resultPane">
                        {r === null ? 'Initializing' :
                            r === 'loading' ? <LoadingIndicator /> :
                                <APIResultPresentation result={r} Component={data => <div>
                                    {data.outputValue
                                        ? <section>
                                            <h3>Value</h3>
                                            <pre>{JSON.stringify(data.outputValue, null, 4)}</pre>
                                        </section>
                                        : null}
                                    {data.evaluationErrors && data.evaluationErrors.length > 0
                                        ? <section>
                                            <h3>Evaluation errors</h3>
                                            <ul>
                                                {(data.evaluationErrors || []).map((err, i) => <li key={i}><EvaluationError error={err} highlight={highlight} /></li>)}
                                            </ul>
                                        </section>
                                        : null}
                                    {data.ast != null ? <section><h3>Syntax tree</h3>
                                        <AST tree={data.ast} highlight={highlight} />
                                    </section> : null}
                                    <h3>Parse errors</h3>
                                    {data.errors && <ParseErrors errors={data.errors} highlight={highlight} />}
                                </div>} />}
                    </div>
                </TabPane>
                <TabPane tabId="desired">
                    <div ref={diffEditorRef} id="diffEditor" className="editor" />
                </TabPane>
            </TabContent>



        </div>
    </>;
}
