import * as React from 'react';
import * as API from '../API';

type P<T, E> = {
    result: API.Result<T, E>;
    Component: (data: T) => React.ReactElement<T>
};

type S = {
};


export class APIResultPresentation<T, E> extends React.Component<P<T, E>, S> {
    constructor(props: P<T, E>) {
        super(props);
        this.state = {
            merchants: null
        };
    }

    render() {
        const d = this.props.result;

        return API.isError(d) ? <div className="error">Error: <pre>{JSON.stringify(d.error, null, 2)}</pre></div> :
                API.isFailure(d) ? <div className="error">
                    <span>{d.failure.statusText}</span>
                    <pre>{d.failure.payload}</pre>
                </div> :
                    this.props.Component(d.success);
    }
}
