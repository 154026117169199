import { useMemo, useState, useEffect, useRef, createRef } from 'react';
import { Subject } from 'rxjs';
import { distinctUntilChanged, sampleTime } from 'rxjs/operators';
import { useCallback } from 'react';
import * as Monaco from 'monaco-editor';
import useResizeObserver from 'use-resize-observer';


export function useDelayState<T>(initialState: T, interval: number = 3000): [T, (next: T) => void] {
    const [state, setState] = useState(initialState);
    const subject = useMemo(() => new Subject<T>(), []);
    useEffect(() => {
        const triggers = subject
            .pipe(sampleTime(interval), distinctUntilChanged());
        triggers.subscribe(setState);
    }, [subject, setState]);
    const update = useCallback((newState: T) => subject.next(newState), [subject]);
    return [state, update];
}




export function useLocalStoragePersistance(key: string, value: string) {
    useEffect(() => {
        window.localStorage.setItem(key, value);
    }, [value]);

}


export function useMonaco(options?: { language?: string, value?: string, onChange?: (value: string) => void }): [Monaco.editor.IStandaloneCodeEditor | null, React.Ref<HTMLDivElement>] {
    const [editorInstance, setEditorInstance] = useState<Monaco.editor.IStandaloneCodeEditor | null>(null);
    const { ref, width = 1, height = 1 } = useResizeObserver();
    const lastNode = useRef<{ node: HTMLElement|null }>({ node: null });

    useEffect(() => {
        const node = ref.current;

        if (node && node === lastNode.current.node) {
            // resize event
            console.log('Editor resize', { width, height });
            if (editorInstance) {
                editorInstance.layout({ width, height });
            }
            return;
        }

        lastNode.current.node = node;

        if (editorInstance) {
            console.log('closing editor', editorInstance);
            editorInstance.dispose();
        }

        if (node) {
            const editor = Monaco.editor.create(node, {
                value: options?.value,
                language: options?.language,
                scrollBeyondLastLine: false,
                readOnly: false,
            });

            console.log('initializing editor', editor);

            const onChange = options?.onChange;
            if (onChange) {
                const changeSubscription = editor.onDidChangeModelContent(_ => onChange(editor.getValue()));
            }

            setEditorInstance(editor);
            editor.layout({ width, height });
        } else {
            console.log('leaving closed');

            setEditorInstance(null);
        }

    }, [ref.current, width, height]);

    return [editorInstance, ref as React.Ref<HTMLDivElement>];
}


export function useMonacoDiff(original: { language?: string, value: string }, modified: { language?: string, value: string, onChange?: (value: string) => void  }): [Monaco.editor.IStandaloneDiffEditor | null, React.Ref<HTMLDivElement>] {
    const [editorInstance, setEditorInstance] = useState<Monaco.editor.IStandaloneDiffEditor | null>(null);
    const { ref, width = 1, height = 1 } = useResizeObserver();
    const lastNode = useRef<{ node: HTMLElement | null }>({ node: null });

    const originalModel = useMemo(() => Monaco.editor.createModel(original.value, original.language), []);
    useEffect(() => {
        originalModel.setValue(original.value);
    }, [original.value]);


    const modifiedModel = useMemo(() => Monaco.editor.createModel(modified.value, modified.language), []);
    useEffect(() => {
        modifiedModel.setValue(modified.value);
    }, [modified.value]);


    useEffect(() => {
        const node = ref.current;

        if (node && node === lastNode.current.node) {
            // resize event
            console.log('Editor resize', { width, height });
            if (editorInstance) {
                editorInstance.layout();
            }
            return;
        }

        lastNode.current.node = node;

        if (editorInstance) {
            console.log('closing editor', editorInstance);
            editorInstance.dispose();
        }

        if (node) {
            const editor = Monaco.editor.createDiffEditor(node);
            editor.setModel({
                original: originalModel,
                modified: modifiedModel
            });

            const onChange = modified?.onChange;
            if (onChange) {
                const changeSubscription = modifiedModel.onDidChangeContent(_ => onChange(modifiedModel.getValue()));
            }

            setEditorInstance(editor);
        } else {
            console.log('leaving closed');

            setEditorInstance(null);
        }

    }, [ref.current, width, height]);

    return [editorInstance, ref as React.Ref<HTMLDivElement>];
}
