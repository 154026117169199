import { POST } from './API';


export type Position = {
    index: number;
    line: number;
    column: number;
}


export type Range = {
    start: Position;
    end: Position;
}


export type AST = {
    nodeType: string;
    range: Range;
    children: AST[];
}


export type TestResult = {
    ast: AST|null
    errors: ParseError[] | null,
    evaluationErrors: EvaluationErrorReport[] | null,
    outputValue: any | null
};


export type ParseError = {
    formattedMessage: string,
    report: ParseErrorReport
};


export type ParseErrorReport = {
    position: Position,
    messages: string[],
    snippet: string,
    inner: { [group: string]: ParseErrorReport[] }
};


export type EvaluationErrorReport = EvaluationErrorStack | EvaluationErrorMessage;

export type EvaluationErrorStack = {
    //ast: AST | null,
    message: string;
    inner: EvaluationErrorReport | null;
    position: Position
};

export type EvaluationErrorMessage = {
    message: string,
    position: Position
};


export function isStack(e: EvaluationErrorReport): e is EvaluationErrorStack {
    return 'inner' in e && !!e.inner;
}

export function flattenStacks(err: EvaluationErrorReport) {
    let er: EvaluationErrorReport | null = err;
    let r: EvaluationErrorReport[] = [];
    while (er && isStack(er)) {
        r.push(er);
        er = er.inner;
    }
    return {
        stack: r.reverse(),
        error: er || { message: 'No error message' }
    };
}

export function POSTtest(script: string, library: string, input: string) {
    return POST<TestResult, any>(`/api/XVal/test`, { script, library, input });
}
